import React from 'react'
import { popularWebsites, popularWebsitesSlovensko } from '../../data/popularWebsites'
import CirclesMotionX4 from './CirclesMotionX4'
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import { useBreakpoint } from './contextBreakpointMediaQ';

const clickComp = <StarPurple500Icon sx={{ fontSize: 48 }} />

const optionsLeft = {
    clickComp,
    arrContent: popularWebsites.slice(0, 10),
    numberOfItems: 10,
    distancePixFromCenter: 150,
    itemHeight: 50,
    itemWidth: 80,
    itemHeightBetween: 10, polyLineXSpace: 10,
}
const optionsRight = {
    clickComp,
    arrContent: popularWebsites.slice(10, 19),
    numberOfItems: 9,
    distancePixFromCenter: 60,
    itemHeight: 20,
    itemWidth: 120,
    itemHeightBetween: 15, polyLineXSpace: 10,
}

const optionsTop = {
    clickComp,
    arrContent: popularWebsitesSlovensko.slice(0, 14),
    numberOfItems: 14,
    distancePixFromCenter: 60,
    itemHeight: 20,
    itemWidth: 120,
    itemHeightBetween: 15, polyLineXSpace: 10,
}
const optionsBottom = {
    clickComp,
    arrContent: popularWebsitesSlovensko.slice(14, 27),
    numberOfItems: 13,
    distancePixFromCenter: 60,
    itemHeight: 20,
    itemWidth: 120,
    itemHeightBetween: 15, polyLineXSpace: 10,
}

const CirclesMotionX4PopularWebsites = () => {

    const deviceWidth = useBreakpoint()

    return (<>
        {[ "lg", "xl" ].includes(deviceWidth) &&
            <CirclesMotionX4
                name="popularWebsitesAll"
                arrPosOptions={[
                    [ "left", { ...optionsLeft, distancePixFromCenter: 150 } ],
                    [ "right", { ...optionsRight, distancePixFromCenter: 150 } ],
                    [ "top", { ...optionsTop, distancePixFromCenter: 150 } ],
                    [ "bottom", { ...optionsBottom, distancePixFromCenter: 150 } ]
                ]} />}
        {[ "md" ].includes(deviceWidth) &&
            <CirclesMotionX4
                name="popularWebsitesAll"
                arrPosOptions={[
                    [ "left", { ...optionsLeft, distancePixFromCenter: 100 } ],
                    [ "right", { ...optionsRight, distancePixFromCenter: 100 } ],
                    [ "top", optionsTop ],
                    [ "bottom", optionsBottom ]
                ]} />}
        {[ "sm" ].includes(deviceWidth) && (
            <>
                <CirclesMotionX4
                    name="popularWebsites"
                    arrPosOptions={[
                        [ "left", { ...optionsLeft, distancePixFromCenter: 150 } ],
                        [ "right", { ...optionsRight, distancePixFromCenter: 150 } ]
                    ]} key="lr-c" />
                <CirclesMotionX4
                    name="popularWebsitesSlov1"
                    arrPosOptions={[
                        [ "top", { ...optionsTop, distancePixFromCenter: 150 } ]
                    ]} key="top-c" />
                <CirclesMotionX4
                    name="popularWebsitesSlov2"
                    arrPosOptions={[
                        [ "bottom", { ...optionsBottom, distancePixFromCenter: 150 } ]
                    ]} key="bottom-c" />
            </>
        )}
        {[ "xs" ].includes(deviceWidth) && (
            <>
                <CirclesMotionX4
                    name="popularWebsites1"
                    arrPosOptions={[
                        [ "left", optionsLeft ]
                    ]} key="left-c" />
                <CirclesMotionX4
                    name="popularWebsites2"
                    arrPosOptions={[
                        [ "right", { ...optionsRight, distancePixFromCenter: 150 } ]
                    ]} key="right-c" />
                <CirclesMotionX4
                    name="popularWebsitesSlov1"
                    arrPosOptions={[
                        [ "left", { ...optionsTop, distancePixFromCenter: 150 } ]
                    ]} key="top-c" />
                <CirclesMotionX4
                    name="popularWebsitesSlov2"
                    arrPosOptions={[
                        [ "right", { ...optionsBottom, distancePixFromCenter: 150 } ]
                    ]} key="bottom-c" />
            </>
        )}
    </>)
}

export default CirclesMotionX4PopularWebsites
